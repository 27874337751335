import React from 'react';

interface PageTitleProps {
  children: React.ReactNode;
}

const PageTitle: React.FC<PageTitleProps> = ({ children }) => {
  return <h1 className="mb-4 border-b-2 border-darkBlue-3 pb-2 text-24 font-bold">{children}</h1>;
};

export default PageTitle;
