import React, { useState, useMemo } from 'react';
import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';
import MenuOpenButton from '../common/MenuOpenButton';
import Breadcrumb from '../common/Breadcrumb';
import { Menu } from '../../hooks/useMenus';

interface LayoutProps {
  children: React.ReactNode;
  onLogout: () => void;
  menus: Menu[];
  currentPath: string;
  resetChat: () => void;
}

const Layout: React.FC<LayoutProps> = ({ children, onLogout, menus, currentPath, resetChat }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const breadcrumbItems = useMemo(() => {
    const items = [{ label: 'ホーム', path: '/' }];

    if (currentPath === '/freestyle') {
      items.push({ label: 'フリースタイル', path: '/freestyle' });
    } else if (currentPath.startsWith('/generate/')) {
      const menuName = currentPath.split('/')[2];
      const menu = menus.find((m) => m.path === menuName);
      if (menu) {
        items.push({ label: menu.name, path: `/generate/${menu.path}` });

        if (currentPath.endsWith('/edit')) {
          items.push({ label: '編集', path: currentPath });
        } else if (currentPath.endsWith('/confirm')) {
          items.push({ label: '確認', path: currentPath });
        }
      }
    }

    return items;
  }, [currentPath, menus]);

  const isFreestyle = currentPath.includes('/freestyle');

  return (
    <div className="flex h-screen flex-col">
      <Header onLogout={onLogout} />
      <div className="flex flex-1 overflow-hidden">
        <Sidebar
          isOpen={isSidebarOpen}
          onClose={toggleSidebar}
          menus={menus}
          resetChat={resetChat}
        />
        <div
          className={`
          min-w-[500px] flex-1 
          transition-all duration-300 
          ${isSidebarOpen ? 'ml-64' : 'ml-0'}
        `}
        >
          <main className="flex h-full flex-col overflow-auto bg-black-1">
            <div className="flex items-center px-6 pb-4 pt-6">
              {!isSidebarOpen && <MenuOpenButton onClick={toggleSidebar} />}
              <Breadcrumb items={breadcrumbItems} />
            </div>
            <div className={`flex flex-1 justify-center ${isFreestyle ? 'overflow-hidden' : ''}`}>
              {children}
            </div>
          </main>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
