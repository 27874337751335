import React from 'react';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  variant?: 'primary' | 'secondary';
}

const Button: React.FC<ButtonProps> = ({ label, variant = 'primary', className, ...props }) => {
  const baseClasses =
    'px-4 py-2 rounded-[10px] border-2 shadow-[5px_5px_5px_0px_rgba(0,0,0,1)] transition-colors duration-200 focus:outline-none focus-visible:border-black-g';
  const variantClasses = {
    primary: 'bg-darkBlue-1 text-black-g border-black-g hover:bg-darkBlue-2',
    secondary: 'bg-black-1 text-black-g border-black-g hover:bg-black-2',
  };

  return (
    <button className={`${baseClasses} ${variantClasses[variant]} ${className}`} {...props}>
      {label}
    </button>
  );
};

export default Button;
