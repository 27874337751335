import React, { useRef, useEffect } from 'react';
import Button from '../../../components/common/Button';
import { MenuData } from '../../../types/menu';
import PageTitle from '../../../components/common/PageTitle';

interface GenerateConfirmPageProps {
  menuData: MenuData;
  content: string;
  onNextPost: () => void;
  onCopy: () => void;
  onBack: () => void;
}

const GenerateConfirmPage: React.FC<GenerateConfirmPageProps> = ({
  menuData,
  content,
  onNextPost,
  onCopy,
  onBack,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [content]);

  return (
    <div className="w-[672px] bg-black-1">
      <div className="mb-6 rounded-10 bg-black-0 p-6">
        <PageTitle>{menuData.name}</PageTitle>
        <div className="mb-4">
          <textarea
            ref={textareaRef}
            value={content}
            readOnly
            className="w-full resize-none whitespace-pre-wrap rounded-10 border border-black-g bg-black-1 px-3 py-2"
            style={{ minHeight: '200px', overflow: 'hidden' }}
          />
        </div>
        <div className="flex items-center justify-between">
          <Button label="編集に戻る" onClick={onBack} variant="secondary" className="font-bold" />
          <div className="space-x-4">
            <Button label="コピー" onClick={onCopy} className="font-bold" />
            <Button label="次の投稿文を作成" onClick={onNextPost} className="font-bold" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenerateConfirmPage;
