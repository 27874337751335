import React from 'react';
import Logo from '../common/Logo';
import Button from '../common/Button';

interface HeaderProps {
  onLogout: () => void;
}

const Header: React.FC<HeaderProps> = ({ onLogout }) => {
  return (
    <header className="flex h-[72px] items-center justify-between border-b border-black-g bg-black-0 px-4">
      <Logo />
      <Button label="ログアウト" variant="secondary" onClick={onLogout} />
    </header>
  );
};

export default Header;
