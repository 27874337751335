import React from 'react';

interface RadioProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'label'> {
  label: React.ReactNode;
}

const Radio: React.FC<RadioProps> = ({ label, ...props }) => {
  return (
    <label className="flex cursor-pointer items-center space-x-2">
      <input
        type="radio"
        className="size-4 border-black-2 text-blue-3 focus:ring-blue-3"
        {...props}
      />
      <span className="text-16 text-black-g">{label}</span>
    </label>
  );
};

export default Radio;
