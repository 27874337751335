import React, { useRef, useEffect, useCallback, useMemo } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { debounce } from 'lodash';
import ReactMarkdown from 'react-markdown';
import type { Components } from 'react-markdown';
import { ChatMessage, CodeComponentProps, PreComponentProps } from '../../../types/chat';

interface ChatHistoryProps {
  messages: ChatMessage[];
  isAtBottom: boolean;
  setIsAtBottom: (isAtBottom: boolean) => void;
}

const ChatHistory: React.FC<ChatHistoryProps> = ({ messages, isAtBottom, setIsAtBottom }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const checkIfAtBottom = useCallback(() => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      const atBottom = Math.abs(scrollHeight - clientHeight - scrollTop) < 1;
      setIsAtBottom(atBottom);
    }
  }, [setIsAtBottom]);

  const debouncedCheckIfAtBottom = useMemo(() => debounce(checkIfAtBottom, 100), [checkIfAtBottom]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', debouncedCheckIfAtBottom);
      return () => container.removeEventListener('scroll', debouncedCheckIfAtBottom);
    }
  }, [debouncedCheckIfAtBottom]);

  useEffect(() => {
    if (isAtBottom) {
      scrollToBottom();
    }
  }, [messages, isAtBottom, scrollToBottom]);

  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert('テキストがコピーされました');
      })
      .catch((err) => {
        console.error('コピーに失敗しました:', err);
      });
  };

  const ImageComponent = React.memo(({ src, alt }: { src?: string; alt?: string }) => (
    <img src={src} alt={alt} className="my-4 max-w-full rounded" loading="lazy" />
  ));
  ImageComponent.displayName = 'ImageComponent';

  const markdownComponents: Components = useMemo(
    () => ({
      // 見出し
      h1: ({ children }) => <h1 className="mb-4 text-2xl font-bold">{children}</h1>,
      h2: ({ children }) => <h2 className="mb-3 text-xl font-bold">{children}</h2>,
      h3: ({ children }) => <h3 className="mb-2 text-lg font-bold">{children}</h3>,
      h4: ({ children }) => <h4 className="mb-2 text-base font-bold">{children}</h4>,

      // 段落とhr
      p: ({ children }) => <p className="mb-4">{children}</p>,
      hr: () => <hr className="my-4 border-t border-gray-300" />,

      // リスト
      ul: ({ children }) => <ul className="mb-4 ml-5 list-disc space-y-1">{children}</ul>,
      ol: ({ children }) => <ol className="mb-4 ml-4 list-decimal space-y-1">{children}</ol>,
      li: ({ children }) => <li>{children}</li>,

      // リンクと画像
      a: ({ href, children }) => (
        <a
          href={href}
          className="text-blue-600 hover:underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </a>
      ),
      img: ({ src, alt }) => (
        <div className="relative my-4 aspect-auto w-full">
          <img src={src} alt={alt} className="rounded object-contain" loading="lazy" />
        </div>
      ),

      // コード
      code: ({ inline, className, children }: CodeComponentProps) =>
        inline ? (
          <code className="rounded bg-gray-100 px-1 py-0.5 font-mono text-sm">{children}</code>
        ) : (
          <code className="block font-mono">{children}</code>
        ),
      pre: ({ children }: PreComponentProps) => (
        <pre className="mb-4 overflow-x-auto rounded bg-gray-100 p-4 font-mono text-sm">
          {children}
        </pre>
      ),

      // 強調
      em: ({ children }) => <em className="italic">{children}</em>,
      strong: ({ children }) => <strong className="font-bold">{children}</strong>,
    }),
    []
  );

  return (
    <div
      ref={containerRef}
      className="mx-6 mb-6 flex-1 overflow-auto rounded-lg bg-black-0 p-6 shadow"
    >
      <div className="flex flex-col space-y-2">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}
          >
            <div
              className={`max-w-full rounded-10 ${
                message.role === 'user'
                  ? 'bg-darkBlue-3 px-4 py-2 text-black-0'
                  : 'bg-black-0 px-4 py-2 text-black-g'
              }`}
            >
              {message.role === 'user' ? (
                <p className="whitespace-pre-wrap text-16">{message.content}</p>
              ) : (
                <div className="prose prose-sm max-w-none overflow-x-hidden text-16">
                  <ReactMarkdown components={markdownComponents}>{message.content}</ReactMarkdown>
                </div>
              )}
              {message.role === 'assistant' && message.content && (
                <button
                  onClick={() => copyToClipboard(message.content)}
                  className="mt-4 text-black-g hover:text-darkBlue-3"
                  aria-label="Copy message"
                >
                  <ContentCopyIcon fontSize="small" />
                </button>
              )}
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
    </div>
  );
};

export default ChatHistory;
