import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import Layout from './components/layout/Layout';
import HomePage from './features/home/components/HomePage';
import FreestylePage from './features/freestyle/components/FreestylePage';
import { useAuth } from './hooks/useAuth';
import GeneratePage from './features/generate/components/GeneratePage';
import WarningPopup from './components/common/WarningPopup';
import { useWarningPopup } from './hooks/useWarningPopup';
import { useMenus } from './hooks/useMenus';
import { useChatHistory } from './features/freestyle/hooks/useChatHistory';

const App: React.FC = () => {
  const { isAuthenticated, user, checkAuth, login, logout } = useAuth();
  const { isOpen, closePopup, openPopup } = useWarningPopup();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { menus, fetchMenus } = useMenus();
  const { chatHistory, addMessage, isLoading: chatLoading, resetChat } = useChatHistory();

  useEffect(() => {
    const initializeApp = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');

      if (code) {
        try {
          await login(code);
          openPopup();
          navigate('/');
        } catch (error) {
          console.error('Login failed:', error);
        }
      } else {
        try {
          await checkAuth();
        } catch (error) {
          console.error('Auth check failed:', error);
        }
      }

      setIsLoading(false);
    };

    initializeApp();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (isAuthenticated) {
      fetchMenus();
    }
  }, [isAuthenticated, fetchMenus]);

  useEffect(() => {
    if (location.pathname === '/freestyle') {
      resetChat();
    }
  }, [location.pathname, resetChat]);

  const handleLogout = async () => {
    try {
      await logout();
      const logoutUrl = `https://${process.env.REACT_APP_COGNITO_DOMAIN}/logout?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&logout_uri=${process.env.REACT_APP_COGNITO_REDIRECT_URI}`;
      window.location.href = logoutUrl;
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  if (isLoading || !isAuthenticated) {
    return <div></div>;
  }

  return (
    <Layout
      onLogout={handleLogout}
      menus={menus}
      currentPath={location.pathname}
      resetChat={resetChat}
    >
      <WarningPopup isOpen={isOpen} onClose={closePopup} />
      <Routes>
        <Route path="/" element={<HomePage user={user} menus={menus} />} />
        <Route
          path="/freestyle"
          element={
            <FreestylePage
              chatHistory={chatHistory}
              addMessage={addMessage}
              isLoading={chatLoading}
            />
          }
        />
        <Route path="/generate/:menuName" element={<GeneratePage />} />
        <Route path="/generate/:menuName/edit" element={<GeneratePage />} />
        <Route path="/generate/:menuName/confirm" element={<GeneratePage />} />
      </Routes>
    </Layout>
  );
};

export default App;
