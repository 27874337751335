import React from 'react';

interface MenuOpenButtonProps {
  onClick: () => void;
}

const MenuOpenButton: React.FC<MenuOpenButtonProps> = ({ onClick }) => {
  return (
    <button onClick={onClick} className="mr-4 rounded-full hover:bg-black-1">
      <img src="/button-open.svg" alt="Open menu" className="size-5" />
    </button>
  );
};

export default MenuOpenButton;
