import React from 'react';

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
}

const Checkbox: React.FC<CheckboxProps> = ({ label, ...props }) => {
  return (
    <label className="flex cursor-pointer items-center space-x-2">
      <input
        type="checkbox"
        className="size-[15px] rounded border-black-2 text-blue-3 focus:ring-blue-3"
        {...props}
      />
      <span className="text-14 text-black-g">{label}</span>
    </label>
  );
};

export default Checkbox;
