import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import MenuCloseButton from '../../components/common/MenuCloseButton';
import { Menu } from '../../hooks/useMenus';
import { useLocation } from 'react-router-dom';

interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
  menus: Menu[];
  resetChat: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, onClose, menus, resetChat }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleMenuClick = (menu: Menu) => {
    if (menu.path === 'freestyle') {
      resetChat();
      navigate('/freestyle');
    } else {
      navigate(`/generate/${menu.path}`);
    }
  };

  const isMenuActive = (menuPath: string) => {
    if (menuPath === 'freestyle') {
      return location.pathname === '/freestyle';
    } else {
      return location.pathname.startsWith(`/generate/${menuPath}`);
    }
  };

  return (
    <aside
      className={`
        fixed left-0 top-[72px] h-[calc(100vh-72px-53px)] w-64 
        border-r border-black-g bg-black-0
        transition-transform duration-300 ease-in-out
        ${isOpen ? 'translate-x-0' : '-translate-x-full'}
      `}
    >
      <div className="flex h-full flex-col justify-between p-4">
        <nav>
          <ul className="space-y-2">
            <li key="home">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  `block px-4 py-2 text-14 rounded-[10px] h-10 flex items-center
                  ${
                    isActive
                      ? 'bg-darkBlue-3 text-black-0'
                      : 'bg-black-0 text-black-g hover:text-black-0 hover:bg-darkBlue-3'
                  }`
                }
              >
                ホーム
              </NavLink>
            </li>
            {menus.map((menu) => (
              <li key={menu.id}>
                <button
                  onClick={() => handleMenuClick(menu)}
                  className={`flex h-10 w-full items-center rounded-10 px-4 py-2 text-left text-14
                    ${
                      isMenuActive(menu.path)
                        ? 'bg-darkBlue-3 text-black-0'
                        : 'bg-black-0 text-black-g hover:bg-darkBlue-3 hover:text-black-0'
                    }`}
                >
                  {menu.name}
                </button>
              </li>
            ))}
          </ul>
        </nav>
        <div className="flex justify-end">
          <MenuCloseButton onClick={onClose} />
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
