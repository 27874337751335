import { useState } from 'react';

export const useWarningPopup = () => {
  const [isOpen, setIsOpen] = useState(false);

  const closePopup = () => {
    setIsOpen(false);
  };

  const openPopup = () => {
    setIsOpen(true);
  };

  return { isOpen, closePopup, openPopup };
};
