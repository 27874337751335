// src/components/common/Breadcrumb.tsx

import React from 'react';
import { Link } from 'react-router-dom';

interface BreadcrumbProps {
  items: { label: string; path?: string }[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
  return (
    <div className="flex items-center">
      {items.map((item, index) => (
        <React.Fragment key={index}>
          {index > 0 && <span className="mx-4 text-black-g">&gt;</span>}
          {index === items.length - 1 || !item.path ? (
            <span className={index === items.length - 1 ? 'text-black-g' : 'text-darkBlue-3'}>
              {item.label}
            </span>
          ) : (
            <Link to={item.path} className="text-darkBlue-3 hover:underline">
              {item.label}
            </Link>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Breadcrumb;
