import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="bg-black-g py-4 text-center">
      <p className="text-14 text-black-0">
        © {new Date().getFullYear()} TOKYU LIVABLE, INC. All Rights Reserved.
      </p>
    </footer>
  );
};

export default Footer;
