import React, { useState, useCallback } from 'react';
import ChatHistory from './ChatHistory';
import ChatInput from './ChatInput';
import PageTitle from '../../../components/common/PageTitle';

interface FreestylePageProps {
  chatHistory: Array<{ role: 'user' | 'assistant'; content: string }>;
  addMessage: (message: string) => void;
  isLoading: boolean;
}

const FreestylePage: React.FC<FreestylePageProps> = ({ chatHistory, addMessage, isLoading }) => {
  const [isAtBottom, setIsAtBottom] = useState(true);

  const handleSendMessage = useCallback(
    (message: string) => {
      addMessage(message);
    },
    [addMessage]
  );

  return (
    <div className="flex flex-1 flex-col overflow-hidden">
      <div className="px-6">
        <PageTitle>フリースタイル</PageTitle>
      </div>
      <div className="flex flex-1 flex-col overflow-hidden">
        <ChatHistory messages={chatHistory} isAtBottom={isAtBottom} setIsAtBottom={setIsAtBottom} />
        <div className="mx-6 mb-6 border-t border-gray-200">
          <ChatInput onSend={handleSendMessage} disabled={isLoading} />
        </div>
      </div>
    </div>
  );
};

export default FreestylePage;
