import { useState, useCallback, useRef, useEffect } from 'react';
import { redirectToHostedUI } from '../../../utils/auth';
import axios from 'axios';

interface ChatMessage {
  role: 'user' | 'assistant';
  content: string;
}

export const useChatHistory = () => {
  const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const addMessage = useCallback(
    async (message: string) => {
      if (!isMounted.current) return;

      setChatHistory((prev) => [...prev, { role: 'user', content: message }]);
      setIsLoading(true);

      try {
        const response = await fetch(`${process.env.REACT_APP_CORE_API_URL}/generate/chat`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            menuId: 1,
            messages: [...chatHistory, { role: 'user', content: message }],
          }),
          credentials: 'include',
        });

        const reader = response.body?.getReader();
        const decoder = new TextDecoder();

        if (isMounted.current) {
          setChatHistory((prev) => [...prev, { role: 'assistant', content: '' }]);
        }

        while (true) {
          const { done, value } = (await reader?.read()) ?? { done: true, value: undefined };
          if (done) break;

          const chunk = decoder.decode(value, { stream: true });
          const lines = chunk.split('\n').filter(Boolean);

          for (const line of lines) {
            if (line.startsWith('data:')) {
              const content = line.slice(5).trim();
              if (content !== '[DONE]') {
                const jsonData = JSON.parse(line.slice(6));
                if (isMounted.current) {
                  setChatHistory((prev) => {
                    const newHistory = [...prev];
                    const lastMessage = newHistory[newHistory.length - 1];
                    if (lastMessage && lastMessage.role === 'assistant') {
                      newHistory[newHistory.length - 1] = {
                        role: 'assistant',
                        content: lastMessage.content + jsonData.data,
                      };
                    }
                    return newHistory;
                  });
                }
              }
            }
          }
        }
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status === 401) {
          redirectToHostedUI();
        } else {
          console.error('Chat error:', err);
        }
      } finally {
        if (isMounted.current) {
          setIsLoading(false);
        }
      }
    },
    [chatHistory]
  );

  const resetChat = useCallback(() => {
    if (isMounted.current) {
      setChatHistory([]);
    }
  }, []);

  return { chatHistory, addMessage, isLoading, resetChat };
};
