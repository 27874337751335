import React, { useEffect, useRef } from 'react';
import Button from '../../../components/common/Button';
import TextArea from '../../../components/common/TextArea';
import { MenuData } from '../../../types/menu';
import PageTitle from '../../../components/common/PageTitle';

interface GenerateEditPageProps {
  menuData: MenuData;
  content: string;
  isGenerating: boolean;
  onSubmit: () => void;
  onBack: () => void;
  setGeneratedContent: (content: string) => void;
}

const GenerateEditPage: React.FC<GenerateEditPageProps> = ({
  menuData,
  content,
  setGeneratedContent,
  isGenerating,
  onSubmit,
  onBack,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setGeneratedContent(content);
  }, [setGeneratedContent, content]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight + 2}px`;
    }
  }, [content]);

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setGeneratedContent(e.target.value);
  };

  return (
    <div className="w-[672px] bg-black-1">
      <div className="mb-6 rounded-10 bg-black-0 p-6">
        <PageTitle>{menuData.name}</PageTitle>
        <div className="mb-4 flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mr-2"
          >
            <path
              d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"
              fill="#FFFF00"
              stroke="#000000"
            />
            <line x1="12" y1="9" x2="12" y2="13" stroke="#000000" />
            <line x1="12" y1="17" x2="12.01" y2="17" stroke="#000000" />
          </svg>
          <p>生成された文章は必ずチェックした上でご利用ください</p>
        </div>
        <div className="relative mb-2">
          <TextArea
            label=""
            value={content}
            onChange={handleTextAreaChange}
            placeholder={isGenerating ? '生成中...' : '編集してください...'}
            className="h-40 w-full whitespace-pre-wrap rounded-10 border border-black-g px-3 pb-7 pt-2"
            disabled={isGenerating}
            ref={textareaRef}
          />
          <div className="absolute bottom-3 right-3">{content.length}</div>
        </div>
        <div className="flex items-center justify-between">
          <Button
            label="戻る"
            onClick={onBack}
            variant="secondary"
            disabled={isGenerating}
            className="font-bold"
          />
          <Button
            label="確認画面へ"
            onClick={onSubmit}
            disabled={isGenerating}
            className="font-bold"
          />
        </div>
      </div>
    </div>
  );
};

export default GenerateEditPage;
