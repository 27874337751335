import React, { forwardRef } from 'react';

interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(({ label, ...props }, ref) => {
  return (
    <div>
      {label && (
        <label className="mb-2 block text-black-g" htmlFor={props.id}>
          {label}
        </label>
      )}
      <textarea
        ref={ref}
        className="w-full resize-none rounded-10 border border-black-2 px-3 py-2 text-black-g focus:outline-none focus:ring-2 focus:ring-blue-3"
        {...props}
      />
    </div>
  );
});

TextArea.displayName = 'TextArea';

export default TextArea;
