import React from 'react';

interface MenuCloseButtonProps {
  onClick: () => void;
}

const MenuCloseButton: React.FC<MenuCloseButtonProps> = ({ onClick }) => {
  return (
    <button onClick={onClick} className="rounded-full p-2 hover:bg-black-1">
      <img src="/button-close.svg" alt="Close menu" className="size-6" />
    </button>
  );
};

export default MenuCloseButton;
