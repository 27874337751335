import React, { useState, KeyboardEvent, useMemo, useRef, useEffect } from 'react';

interface ChatInputProps {
  onSend: (message: string) => void;
  disabled?: boolean;
}

const ChatInput: React.FC<ChatInputProps> = ({ onSend, disabled = false }) => {
  const [input, setInput] = useState('');
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const isMac = useMemo(() => {
    return navigator.platform.toUpperCase().indexOf('MAC') >= 0;
  }, []);

  const placeholderText = useMemo(() => {
    return isMac
      ? 'メッセージを入力... (Command + Enter で送信)'
      : 'メッセージを入力... (Ctrl + Enter で送信)';
  }, [isMac]);

  const handleSubmit = () => {
    if (input.trim() && !disabled) {
      onSend(input.trim());
      setInput('');
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && (e.metaKey || e.ctrlKey) && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  useEffect(() => {
    // コンポーネントがマウントされた後、textareaにフォーカスを当てる
    textareaRef.current?.focus();
  }, []);

  return (
    <div className="flex h-[90px] items-center space-x-2">
      <textarea
        ref={textareaRef}
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyDown={handleKeyDown}
        className="h-full grow resize-none rounded-10 border border-black-g bg-black-0 px-4 py-2 text-16 placeholder:text-black-3 focus:outline-none focus-visible:border-black-g"
        placeholder={placeholderText}
        disabled={false} // 常に入力可能
      />
      <button
        onClick={handleSubmit}
        className="h-[90px] w-16 rounded-10 border border-black-g bg-darkBlue-3 text-black-0 shadow-[5px_5px_5px_0px_rgba(0,0,0,1)] focus:outline-none focus-visible:border-black-g"
        disabled={disabled}
      >
        送信
      </button>
    </div>
  );
};

export default ChatInput;
