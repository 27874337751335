import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AppProviders } from './provider';
import { BrowserRouter as Router } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <AppProviders>
    <Router>
      <App />
    </Router>
  </AppProviders>
);
