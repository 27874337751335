import React from 'react';
import { Link } from 'react-router-dom';
import { Menu } from '../../../hooks/useMenus';
import { User } from '../../../types/index';

interface HomePageProps {
  user: User | null;
  menus: Menu[];
}

const HomePage: React.FC<HomePageProps> = ({ user, menus }) => {
  const getMenuPath = (menu: Menu) => {
    return menu.path === 'freestyle' ? '/freestyle' : `/generate/${menu.path}`;
  };

  return (
    <div className="mx-auto max-w-4xl px-8">
      <h1 className="mb-4 text-center text-3xl font-bold text-black-g">
        {user?.department_name} メニュー
      </h1>
      <p className="mb-6 text-center text-black-g">GTP-4 turbo</p>
      <div className="space-y-6">
        {menus.map((menu) => (
          <Link
            key={menu.id}
            to={getMenuPath(menu)}
            className="
              block
              w-[672px] 
              rounded-10 
              border 
              border-black-g 
              bg-black-0 
              p-6 
              shadow-[5px_5px_5px_rgba(0,0,0,1)] 
              transition-shadow 
              hover:shadow-[6px_6px_6px_rgba(0,0,0,1)]
            "
          >
            <h2 className="mb-2 text-xl font-semibold">{menu.name}</h2>
            <p className="text-black-g">{menu.description}</p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default HomePage;
