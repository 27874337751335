import React from 'react';
import { Link } from 'react-router-dom';

const Logo: React.FC = () => {
  return (
    <Link to="/" className="flex h-[40px] items-center" data-testid="logo-container">
      <img src="/logo.png" alt="TOKYU LIVABLE" className="h-full" />
      <div className="mx-4 h-[32px] w-px bg-black-g"></div>
      <span className="text-14 text-black-g">Generative AI System</span>
    </Link>
  );
};

export default Logo;
