import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { coreApiClient } from '../../../lib/api';
import GenerateForm from './GenerateForm';
import GenerateEditPage from './GenerateEditPage';
import GenerateConfirmPage from './GenerateConfirmPage';
import { MenuData } from '../../../types/menu';
import { useGenerateForm } from '../hooks/useGenerateForm';
import axios from 'axios';
import { redirectToHostedUI } from '../../../utils/auth';

type GenerateStep = 'form' | 'edit' | 'confirm';

const GeneratePage: React.FC = () => {
  const { menuName } = useParams<{ menuName: string }>();
  const [menuData, setMenuData] = useState<MenuData | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const getCurrentStep = useCallback((): GenerateStep => {
    if (location.pathname.endsWith('/edit')) return 'edit';
    if (location.pathname.endsWith('/confirm')) return 'confirm';
    return 'form';
  }, [location.pathname]);

  const [currentStep, setCurrentStep] = useState<GenerateStep>(getCurrentStep());

  const {
    formData,
    errors,
    generatedContent,
    setGeneratedContent,
    isGenerating,
    handleInputChange,
    handleGenerate,
    handleEditContentSubmit,
    handleCopyContent,
    handleResetAndGenerateNew,
    validateForm,
  } = useGenerateForm(menuData);

  useEffect(() => {
    if (menuData) return;
    const fetchMenuData = async () => {
      try {
        const response = await coreApiClient.get<MenuData>(`/menus/${menuName}`);
        setMenuData(response.data);
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 401) {
          redirectToHostedUI();
        } else {
          console.error('Failed to fetch menu data:', error);
          navigate('/error');
        }
      }
    };

    if (menuName) {
      fetchMenuData();
    }
  }, [menuData, menuName, navigate]);

  useEffect(() => {
    setCurrentStep(getCurrentStep());
  }, [getCurrentStep]);

  const handleFormSubmit = async () => {
    if (validateForm(formData)) {
      navigate(`/generate/${menuName}/edit`);
      await handleGenerate();
    }
  };

  const handleEditSubmit = () => {
    handleEditContentSubmit();
    navigate(`/generate/${menuName}/confirm`);
  };

  const handleBack = () => {
    if (currentStep === 'edit') {
      navigate(`/generate/${menuName}`);
    } else if (currentStep === 'confirm') {
      navigate(`/generate/${menuName}/edit`);
    }
  };

  const handleNextPost = () => {
    handleResetAndGenerateNew();
    navigate(`/generate/${menuName}`);
  };

  if (!menuData) return <div>Loading...</div>;

  return (
    <>
      {currentStep === 'form' && (
        <GenerateForm
          menuData={menuData}
          formData={formData}
          errors={errors}
          handleInputChange={handleInputChange}
          onSubmit={handleFormSubmit}
        />
      )}
      {currentStep === 'edit' && (
        <GenerateEditPage
          menuData={menuData}
          content={generatedContent}
          setGeneratedContent={setGeneratedContent}
          isGenerating={isGenerating}
          onSubmit={handleEditSubmit}
          onBack={handleBack}
        />
      )}
      {currentStep === 'confirm' && (
        <GenerateConfirmPage
          menuData={menuData}
          content={generatedContent}
          onNextPost={handleNextPost}
          onCopy={handleCopyContent}
          onBack={handleBack}
        />
      )}
    </>
  );
};

export default GeneratePage;
