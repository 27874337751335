import React from 'react';
import Button from './Button';

interface WarningPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

const WarningPopup: React.FC<WarningPopupProps> = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black-g/50">
      <div className="w-full max-w-md rounded-10 bg-white p-6 shadow-xl">
        <div className="mb-4 flex justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="64"
            height="64"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path
              d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"
              fill="#FFFF00"
              stroke="#000000"
            />
            <line x1="12" y1="9" x2="12" y2="13" stroke="#000000" />
            <line x1="12" y1="17" x2="12.01" y2="17" stroke="#000000" />
          </svg>
        </div>
        <h2 className="mb-4 text-center text-xl font-bold">
          生成された文章は必ずチェックした上で
          <br />
          利用してください
        </h2>
        <div className="flex justify-center">
          <Button label="OK" onClick={onClose} variant="primary" className="font-bold" />
        </div>
      </div>
    </div>
  );
};

export default WarningPopup;
