import { useState } from 'react';
import { coreApiClient } from '../lib/api';
import { redirectToHostedUI } from '../utils/auth';
import axios from 'axios';
import { User } from '../types/index';

export const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleAuthError = (error: unknown) => {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 401) {
        redirectToHostedUI();
      } else {
        setError(error.response?.data?.detail || 'An error occurred during authentication');
      }
    } else {
      setError('An unexpected error occurred');
    }
  };

  const checkAuth = async (): Promise<void> => {
    try {
      const response = await coreApiClient.get('/me');
      setIsAuthenticated(true);
      setUser(response.data);
      setError(null);
    } catch (error) {
      setIsAuthenticated(false);
      handleAuthError(error);
    }
  };

  const login = async (code: string): Promise<void> => {
    try {
      const response = await coreApiClient.post('/login', { code });
      setIsAuthenticated(true);
      setUser(response.data.user);
      setError(null);
    } catch (error) {
      handleAuthError(error);
    }
  };

  const logout = async (): Promise<void> => {
    try {
      await coreApiClient.post('/logout');
      // useStateが発火してしまうのでコメントアウト
      // setIsAuthenticated(false);
      setError(null);
    } catch (error) {
      handleAuthError(error);
    }
  };

  return { isAuthenticated, user, error, checkAuth, login, logout };
};
