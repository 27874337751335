import { useState, useCallback } from 'react';
import { coreApiClient } from '../lib/api';
import axios from 'axios';
import { redirectToHostedUI } from '../utils/auth';

export interface Menu {
  id: number;
  name: string;
  path: string;
  description: string;
  system_prompt: string;
  is_common: boolean;
  generate_endpoint: string | null;
  edit_endpoint: string | null;
  confirm_endpoint: string | null;
}

export const useMenus = () => {
  const [menus, setMenus] = useState<Menu[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleMenuError = (error: unknown) => {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 401) {
        redirectToHostedUI();
      } else if (error.response?.status === 404) {
        setError('Menus not found');
      } else {
        setError(error.response?.data?.detail || 'An error occurred while fetching menus');
      }
    } else {
      setError('An unexpected error occurred');
    }
  };

  const fetchMenus = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await coreApiClient.get<{ menus: Menu[] }>('/menus');
      setMenus(response.data.menus);
    } catch (err) {
      handleMenuError(err);
    } finally {
      setLoading(false);
    }
  }, []);

  return { menus, loading, error, fetchMenus };
};
